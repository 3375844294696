import React, { useState}from "react";

import logo from '../assets/image/Hashfair logo 300x300.png' 
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };
  return (
  <>
  <header>  
    <div className="container">
      <div className="navbar">

    <a href="#">
  <img src={logo} width={20} height={40} alt="Description of Image" />
</a>
   
    {/* <div className="btnMU">
                <a href="javascript:void(0);" className="bannerbtn">
              connect
                
                </a>
                </div> */}
   
      </div>
    </div>
  </header>
  </>
  )
}

export default Header