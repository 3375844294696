import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import hfg27 from "../assets/image/hfg_27.png";
import hfg28 from "../assets/image/hfg_28.png";
import hfg29 from "../assets/image/hfg_29.png";
import hfg30 from "../assets/image/hfg_30.png";
import hfg31 from "../assets/image/hfg_31.png";
import hfg32 from "../assets/image/hfg_32.png";
import hfg33 from "../assets/image/hfg_33.png";
import hfg34 from "../assets/image/hfg_34.png";
import hfg35 from "../assets/image/hfg_35.png";
import hfg36 from "../assets/image/hfg_36.png";
import hfg37 from "../assets/image/hfg_37.png";
import hfg38 from "../assets/image/hfg_38.png";
import hfg39 from "../assets/image/hfg_39.png";
import hfg40 from "../assets/image/hfg_40.png";
import hfg41 from "../assets/image/hfg_41.png";
import hfg42 from "../assets/image/hfg_42.png";
import hfg43 from "../assets/image/hfg_43.png";
import hfg44 from "../assets/image/hfg_44.png";
import hfg45 from "../assets/image/hfg_45.png";

const Banner4 = () => {
  const carouselRef = React.useRef();
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };
  const items = [
    hfg27,
    hfg28,
    hfg29,
    hfg30,
    hfg31,
    hfg32,
    hfg33,
    hfg34,
    hfg35,
    hfg36,
    hfg37,
    hfg38,
    hfg39,
    hfg40,
    hfg41,
    hfg42,
    hfg43,
    hfg44,
    hfg45,
  ];

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openLightbox = (index) => {
    setImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };
  return (
    <section className="plan-area" id="planopportunity">
      <div className="container">
        <div className="textsection">
          <h2>(4) For Prediction Game:</h2>

          <ul>
            <li>1. Select the Prediction Game and connect your wallet.</li>
            <li>
              2. By default, the network is set to Polygon, which is suitable
              for hashfair.games.
            </li>
            <li>
              3. Observe the timer and game history, with 5 minutes for placing
              bets and 5 minutes for result calculation.
            </li>
            <li>
              4. Use the "Collect" button in the game history to claim your
              winnings if they are uncollected.{" "}
            </li>
            <li>
              5. The "PNL" section shows your Profit and Loss for your games.
            </li>

            <ul>
              <li>
                Below the timer and history, you'll find different types of
                rounds: "expired," "live," "next," and "canceled.""Live" rounds
                are active with result calculation ongoing.
              </li>
              <li>"Next Round" allows you to place bets on upcoming rounds.</li>
              <li>"Expired" rounds have already finished.</li>
              <li>"Canceled" rounds are voided due to technical issues.</li>
            </ul>
          </ul>
          <h3>Placing Bets in the Next Round:</h3>
          <ul>
            <li>
              1. In the "Next Round" section, choose "up" or " down" based on your prediction.
            </li>
            <li>
              2. Select your bet amount by entering it or using the provided percentage options.
            </li>
            <li>
              3. If you're a new user, enable the confirm button by approving the limit from your wallet.
            </li>
            <li>4. Sign the transaction in the popup to enable the confirm button.</li>
            <li>
              5. Click "confirm" to place your bet.
            </li>
            <li>
              6. After submitting the transaction, you'll see your chosen position and bet amount.
            </li>
            <li>
              7. Wait for the result calculation, which takes 5 minutes.
            </li>
            <li>
              8. If you win, a popup will appear to collect your winnings. You can also collect them later from the history section.
Chart View (New Addition):
</li>
<ul>
    <li>1.  While participating in the Prediction Game, you can access a chart view for additional insights into price trends and patterns.</li>
    <li>2.  The chart view can help you make more informed predictions by visualizing historical data and price movements.</li>
    <li>3.  Use the chart view to analyze past performance and make data-driven decisions when placing your bets.</li>
    <li>4.  Leverage the chart view to enhance your strategy and improve your chances of winning in the Prediction Game.
</li>
</ul>
           </ul>
            </div>
              

            <div className="plancardsection">
          <AliceCarousel
            ref={carouselRef}
            mouseTracking
            items={items.map((src, index) => (
              <div className="item" key={index}>
                <div className="plancard">
                  <img
                    src={src}
                    alt={`Image ${index + 27}`}
                    onClick={() => openLightbox(index)}
                  />
                </div>
              </div>
            ))}
            responsive={responsive}
            controlsStrategy="responsive"
            dotsdi
            buttonsDisabled={false}
            keyboardNavigation={false}
            renderPrevButton={() => (
              <button className="custom-arrow-button">
                <FaArrowLeft className="arrowicon" />
              </button>
            )}
            renderNextButton={() => (
              <button className="custom-arrow-button">
                <FaArrowRight className="arrowicon" />
              </button>
            )}
          />
        </div>
      </div>
      {lightboxIsOpen && (
        <Lightbox
          mainSrc={items[imageIndex]}
          nextSrc={items[(imageIndex + 1) % items.length]}
          prevSrc={items[(imageIndex + items.length - 1) % items.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % items.length)}
        />
      )}
    </section>
  );
};

export default Banner4;
