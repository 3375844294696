import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import hfg13 from "../assets/image/hfg_13.png";
import hfg14 from "../assets/image/hfg_14.png";
import hfg15 from "../assets/image/hfg_15.png";
import hfg16 from "../assets/image/hfg_16.png";
import hfg17 from "../assets/image/hfg_17.png";
import hfg18 from "../assets/image/hfg_18.png";
import hfg19 from "../assets/image/hfg_19.png";
import hfg20 from "../assets/image/hfg_20.png";
import hfg21 from "../assets/image/hfg_21.png";
import hfg22 from "../assets/image/hfg_22.png";
import hfg23 from "../assets/image/hfg_23.png";
import hfg24 from "../assets/image/hfg_24.png";
import hfg25 from "../assets/image/hfg_25.png";
import hfg26 from "../assets/image/hfg_26.png";

const Banner3 = () => {
  const carouselRef = React.useRef();
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };
  const items = [
    hfg13,
    hfg14,
    hfg15,
    hfg16,
    hfg17,
    hfg18,
    hfg19,
    hfg20,
    hfg21,
    hfg22,
    hfg23,
    hfg24,
    hfg25,
    hfg26,
  ];

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openLightbox = (index) => {
    setImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };
  return (
    <section className="plan-area" id="planopportunity">
      <div className="container">
        <div className="textsection">
          <h2>(3) Play Web 3 Games on hashfair.games</h2>

          <ul>
            <a href="hashfair.games.">

            <li> Open the link to hashfair.games.</li>
            </a>
            <li>2. Connect your wallet to the platform.</li>
            <li>
              3. Choose your preferred game, such as Multiplayer Roulette or
              Predictions.
            </li>
          </ul>
          <h3>For Multiplayer Roulette:</h3>
          <ul>
            <li>
            1. After selecting Multiplayer Roulette, check your HFG Token balance in the top right corner.

            </li>
            <li>2. Below, you'll find a table where you can place bets on options like red, black, odd, even, and various numbered ranges.</li>
            <li>3. Consider using the "repeat bets" option if you want to replicate your previous bets.</li>
            <li>
            4. Use the "2x" option to double your bet amount.
            </li>
            <li>5. View your betting history by clicking on the "history" option.</li>
            <li>6. Below, you'll see live Polygon block information and a timer indicating the time remaining for placing bets and confirming transactions.</li>
            <li>7. At 50 seconds, there's a "last bets" warning, and at 40 seconds, no more bets are accepted.</li>
            <li>8. After 20 seconds, the bet button will be disabled, so confirm your bets and transactions before this point.</li>
            <li>9. Adjust your bet amount within the specified minimum and maximum limits.</li>
            <li>10. The "total bet" amount will show the sum of your bets for the current game round.</li>
            <li>11. Confirm your bets and transactions by clicking the appropriate button.</li>
            <li>12. If you're a new user, you may need to approve the contract by clicking the bet button, which will open a transaction approval popup.</li>
          </ul>
         
        </div>

        <div className="plancardsection">
          <AliceCarousel
            ref={carouselRef}
            mouseTracking
            items={items.map((src, index) => (
              <div className="item" key={index}>
                <div className="plancard">
                  <img
                    src={src}
                    alt={`Image ${index + 13}`}
                    onClick={() => openLightbox(index)}
                  />
                </div>
              </div>
            ))}
            responsive={responsive}
            controlsStrategy="responsive"
            dotsdi
            buttonsDisabled={false}
            keyboardNavigation={false}
            renderPrevButton={() => (
              <button className="custom-arrow-button">
                <FaArrowLeft className="arrowicon" />
              </button>
            )}
            renderNextButton={() => (
              <button className="custom-arrow-button">
                <FaArrowRight className="arrowicon" />
              </button>
            )}
          />
        </div>
      </div>
      {lightboxIsOpen && (
        <Lightbox
          mainSrc={items[imageIndex]}
          nextSrc={items[(imageIndex + 1) % items.length]}
          prevSrc={items[(imageIndex + items.length - 1) % items.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % items.length)}
        />
      )}
    </section>
  );
};

export default Banner3;
