import React from 'react'
import Banner from '../components/Banner'
import Banner2 from '../components/Banner2'
import Banner3 from '../components/Banner3'
import Banner4 from '../components/Banner4'


const Home = () => {
  return (
    <>
    
    <Banner />
    <Banner2 />
    <Banner3 />
    <Banner4 />
   
    </>
  )
}

export default Home