import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import hfg1 from "../assets/image/hfg_1.png";
import hfg2 from "../assets/image/hfg_2.png";
import hfg3 from "../assets/image/hfg_3.png";
import hfg4 from "../assets/image/hfg_4.png";

const Banner = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const carouselRef = React.useRef();
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };
  const items = [hfg1, hfg2, hfg3, hfg4];

  const openLightbox = (index) => {
    setImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  return (
    <section className="plan-area" id="planopportunity">
      <div className="container">
        <div className="headingsection">
          <h1>A Complete Guide From Acquiring HFG Tokens to Playing Web 3 Games</h1>
        </div>
        <div className="textsection">
          <h2>(1) Get HFG Tokens</h2>
          <a href="https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&outputCurrency=0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf">
            Swap on Uniswap
          </a>
          <ul>
            <li>1. Go to Uniswap and connect your preferred wallet.</li>
            <li>2. Change the network to Polygon on Uniswap.</li>
            <li>3. Select the token you want to swap for HFG Tokens from the dropdown list.</li>
            <li>4. Swap your chosen token for HFG Tokens.</li>
          </ul>
        </div>

        <div className="plancardsection">
          <AliceCarousel
            ref={carouselRef}
            mouseTracking
            items={items.map((src, index) => (
              <div className="item" key={index}>
                <div className="plancard">
                  <img
                    src={src}
                    alt={`Image ${index + 1}`}
                    onClick={() => openLightbox(index)}
                  />
                </div>
              </div>
            ))}
            responsive={responsive}
            controlsStrategy="responsive"
            dotsdi
            buttonsDisabled={false}
            keyboardNavigation={false}
            renderPrevButton={() => (
              <button className="custom-arrow-button">
                <FaArrowLeft className="arrowicon" />
              </button>
            )}
            renderNextButton={() => (
              <button className="custom-arrow-button">
                <FaArrowRight className="arrowicon" />
              </button>
            )}
          />
        </div>
      </div>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={items[imageIndex]}
          nextSrc={items[(imageIndex + 1) % items.length]}
          prevSrc={items[(imageIndex + items.length - 1) % items.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setImageIndex((imageIndex + items.length - 1) % items.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % items.length)}
        />
      )}
    </section>
  );
};

export default Banner;
