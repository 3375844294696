import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FaArrowLeft, FaArrowRight, FaCopy } from "react-icons/fa";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import hfg5 from "../assets/image/hfg_5.png";
import hfg6 from "../assets/image/hfg_6.png";
import hfg7 from "../assets/image/hfg_7.png";
import hfg8 from "../assets/image/hfg_8.png";
import hfg9 from "../assets/image/hfg_9.png";
import hfg10 from "../assets/image/hfg_10.png";
import hfg11 from "../assets/image/hfg_11.png";
import hfg12 from "../assets/image/hfg_12.png";

const Banner2 = () => {
  const [isCopied, setIsCopied] = useState(false);
  const address = "0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf";

  const handleCopyClick = () => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(address)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    } else {
        const textarea = document.createElement("textarea");
        textarea.value = address;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textarea);
    }
};







const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
const [imageIndex, setImageIndex] = useState(0);

const openLightbox = (index) => {
  setImageIndex(index);
  setLightboxIsOpen(true);
};

const closeLightbox = () => {
  setLightboxIsOpen(false);
};

const carouselRef = React.useRef();
const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};
const items = [hfg5, hfg6, hfg7, hfg8, hfg9, hfg10, hfg11, hfg12];

return (
  <section className="plan-area" id="planopportunity">
    <div className="container">
      <div className="textsection">
        {/* Your content here */}
      </div>
      <div className="plancardsection">
        <AliceCarousel
          ref={carouselRef}
          mouseTracking
          items={items.map((src, index) => (
            <div className="item" key={index}>
              <div className="plancard">
                <img
                  src={src}
                  alt={`Image ${index + 1}`}
                  onClick={() => openLightbox(index)}
                />
              </div>
            </div>
          ))}
          responsive={responsive}
          controlsStrategy="responsive"
          dotsdi
          buttonsDisabled={false}
          keyboardNavigation={false}
          renderPrevButton={() => (
            <button className="custom-arrow-button">
              <FaArrowLeft className="arrowicon" />
            </button>
          )}
          renderNextButton={() => (
            <button className="custom-arrow-button">
              <FaArrowRight className="arrowicon" />
            </button>
          )}
        />
      </div>
    </div>
    {lightboxIsOpen && (
      <Lightbox
        mainSrc={items[imageIndex]}
        nextSrc={items[(imageIndex + 1) % items.length]}
        prevSrc={items[(imageIndex + items.length - 1) % items.length]}
        onCloseRequest={closeLightbox}
        onMovePrevRequest={() =>
          setImageIndex((imageIndex + items.length - 1) % items.length)
        }
        onMoveNextRequest={() => setImageIndex((imageIndex + 1) % items.length)}
      />
    )}
  </section>
);
};

export default Banner2;
